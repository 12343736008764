
export const Swirl = () => (
  <svg className="absolute" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M10,50 Q25,25 50,50 T90,50"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      className="animate-draw"
    />
  </svg>
);
