import React from 'react';
import { Card, CardContent } from './components/Card';
import { CalendarIcon, EnvelopeIcon, UserGroupIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { Swirl } from './components/Swirl';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-blue-50">
      {/* Decorative Elements */}
      <div className="fixed inset-0 ">
        <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-blue-100/50 rounded-full blur-3xl transform translate-x-1/2 -translate-y-1/2" />
        <div className="absolute bottom-0 left-0 w-[500px] h-[500px] bg-blue-200/50 rounded-full blur-3xl transform -translate-x-1/2 translate-y-1/2" />
        
        {/* Top Swirls */}
        <div className="absolute -top-20 right-0 text-blue-200 w-96 h-[500px] transform rotate-[100deg]">
          <Swirl />
        </div>
        <div className="absolute -top-40 right-20 text-blue-300 w-96 h-[500px] transform rotate-[100deg]">
          <Swirl />
        </div>
        
        {/* Bottom Swirls */}
        <div className="absolute -bottom-20 left-0 text-blue-200 w-96 h-96 transform -rotate-[20deg]">
          <Swirl />
        </div>
        <div className="absolute -bottom-32 left-20 text-blue-300 w-96 h-96 transform -rotate-[60deg]">
          <Swirl />
        </div>
      </div>

      <main className="relative max-w-5xl mx-auto px-4 py-20">
        {/* Header Section */}
        <header className="text-center mb-20">
          <h1 className="text-5xl sm:text-6xl font-bold text-blue-900 mb-6 tracking-tight font-serif">
            Trauma, Verlies en Angst
          </h1>
          <div className="h-1 w-32 bg-blue-500 mx-auto rounded-full mb-6" />
          <h2 className="text-3xl sm:text-4xl font-semibold text-blue-800/90 font-serif">
            Praktijk 't Gooi
          </h2>
        </header>

        {/* Content Grid */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {/* Info Card */}
          <Card className="overflow-hidden backdrop-blur bg-white/90 border-0 shadow-xl hover:shadow-2xl transition-all duration-300">
            <div className="p-8 pb-6 bg-gradient-to-br from-blue-50 to-transparent">
              <h3 className="text-2xl font-semibold font-serif text-blue-900 mb-6">Informatie</h3>
            </div>
            <CardContent className="space-y-8 p-8 pt-4">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-blue-50 rounded-xl flex-shrink-0">
                  <CalendarIcon className="w-6 h-6 text-blue-600" />
                </div>
                <div>
                  <p className="font-medium text-blue-900">Start Datum</p>
                  <p className="text-lg text-gray-600">Per 1 januari 2025</p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="p-3 bg-blue-50 rounded-xl flex-shrink-0">
                  <UserGroupIcon className="w-6 h-6 text-blue-600" />
                </div>
                <div>
                  <p className="font-medium text-blue-900">Doelgroep</p>
                  <p className="text-lg text-gray-600">Kind / Jeugd / (Jong) Volwassenen</p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="p-3 bg-blue-50 rounded-xl flex-shrink-0">
                  <UserCircleIcon className="w-6 h-6 text-blue-600" />
                </div>
                <div>
                  <p className="font-medium text-blue-900">Focus</p>
                  <p className="text-lg text-gray-600">Behandelen / Verwerken / Versterken</p>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Contact Card */}
          <Card className="overflow-hidden backdrop-blur bg-gradient-to-br from-blue-900 to-blue-800 border-0 shadow-xl hover:shadow-2xl transition-all duration-300">
            <div className="p-8 pb-6 border-b border-white/10">
              <h3 className="text-2xl font-semibold text-white font-serif">Contact</h3>
            </div>
            <CardContent className="p-8 text-white">
              <p className="text-blue-100 mb-4 mt-4">U kunt ons bereiken via:</p>
              <a 
                href="mailto:info@traumaverliesangstpraktijktgooi.nl"
                className="group flex items-center gap-4 hover:opacity-80 transition-opacity"
              >
                <div className="p-3 bg-white/10 rounded-xl flex-shrink-0">
                  <EnvelopeIcon className="w-6 h-6 text-blue-100" />
                </div>
                <div className="min-w-0">
                  <p className="font-medium text-blue-100">Email</p>
                  <p className="text-lg text-blue-200 block truncate sm:text-clip sm:whitespace-normal">
                    info@traumaverliesangstpraktijktgooi.nl
                  </p>
                  <span className="block sm:hidden text-sm text-blue-200/80 mt-1">Tik om te mailen</span>
                </div>
              </a>
            </CardContent>
          </Card>
        </div>

        {/* Footer */}
        <footer className="text-center text-sm text-blue-600">
          <p>© {new Date().getFullYear()} Praktijk 't Gooi. Alle rechten voorbehouden.</p>
        </footer>
      </main>
    </div>
  );
};

export default LandingPage;